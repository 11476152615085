import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import { colors } from '../../theme'
import FooterMenuButton from '../FooterMenuButton'

import styles from './styles'


const useStyles = createUseStyles(styles)
const FooterMenu = (props) => {
  const classes = useStyles(props)
  const {
    onClickLink,
    currentPath,
    links,
    classnames,
    horizontal,
    className,
    title,
    route,
    routeParams,
    link,
    label,
    target,
    isSelected,
  } = props

  const Separator = () => <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
  const SeparatorBar = () => <span style={{ color: colors.pureWhite, margin: '0 1rem' }}>|</span>
  const checkIsSelected = (entry) => ((currentPath && currentPath === entry.link) || entry.isSelected)

  const renderLink = (p, i) => (
    <Fragment key={parseInt(i.toString(), 10)}>
      {horizontal && i !== 0 && <SeparatorBar />}
      <FooterMenuButton
        {...p}
        horizontal={horizontal}
        onClick={() => {
          if (typeof p.onClick === 'function') {
            p.onClick()
          }
          onClickLink()
        }}
        isSelected={checkIsSelected(p)}
      />
    </Fragment>
  )

  const renderLinks = () => (links && links.map((p, i) => renderLink(p, i)))

  return (
    <div className={cx(classes.container, horizontal && classes.containerRow, classnames.container, className)}>
      {link && (renderLink({ label, link, target, isSelected, route, routeParams }, 0)) }
      {!link && (
        <>
          <div className={cx(classes.title, horizontal && classes.titleRow)}>{title}</div>
          {horizontal && <Separator />}
          {renderLinks()}
        </>
      )}
    </div>
  )
}

FooterMenu.propTypes = {
  classnames: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  links: PropTypes.arrayOf(PropTypes.shape(FooterMenuButton.propTypes)),
  onClickLink: PropTypes.func,
  currentPath: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
  label: PropTypes.string,
  target: PropTypes.string,
  isSelected: PropTypes.bool,
}

FooterMenu.defaultProps = {
  classnames: {},
  className: null,
  horizontal: false,
  links: null,
  onClickLink: () => undefined,
  currentPath: null,
  title: '',
  link: '',
  route: null,
  routeParams: null,
  label: '',
  target: '',
  isSelected: false,
}

export default withMemo()(FooterMenu)
