import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const FilterBoxEntry = (props) => {
  const classes = useStyles(props)
  const {
    className,
    name,
    label,
    options,
    value,
    onChange,
    isMulti,
  } = props

  const handleChange = useCallback((cname) => {
    const val = Array.from(value) || []
    const index = val.indexOf(cname)

    if (cname === null) {
      val.splice(0)
    } else if (isMulti) {
      if (index === -1) {
        val.push(cname)
      } else {
        val.splice(index, 1)
      }
    } else if (index === -1) {
      val.splice(0)
      val.push(cname)
    }

    onChange({
      name,
      value: isMulti ? val : val[0],
    })
  }, [onChange, name, value, isMulti])

  const getIsSelected = useCallback((v) => Array.from(value)
    .includes(v) || value === v || (!Array.from(value)?.length && v === null), [value])

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <div
          className={classes.label}
          dangerouslySetInnerHTML={{ __html: label }}
        />
        <div
          className={cx(
            classes.entries
          )}
        >
          {
            options.map((option, i) => (
              <div
                className={cx(classes.entry, getIsSelected(option.value) && classes.selected)}
                key={i}
                onClick={() => handleChange(option.value)}
                role="presentation"
              >
                {option.label}
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

FilterBoxEntry.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
}

FilterBoxEntry.defaultProps = {
  className: '',
  name: '',
  label: '',
  options: [],
  value: [],
  onChange: null,
  isMulti: false,
}

export default withMemo()(FilterBoxEntry)
