import cx from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import A from '../A'
import CloudinaryImage from '../CloudinaryImage'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'

import styles from './styles'


const useStyles = createUseStyles(styles)

const Countdown = (props) => {
  const classes = useStyles(props)
  const { className, text, date, image, copyright, legalsLabel, legalsLink } = props
  const { t } = useTranslation()
  const [currentDate, setCurrentDate] = useState(new Date())

  useEffect(() => {
    const handler = setInterval(() => {
      setCurrentDate(new Date())
    }, 1000 * 60)

    return () => {
      clearInterval(handler)
    }
  }, [])

  const renderCountdown = useCallback(() => {
    const now = moment(currentDate)
    const then = moment(date)

    const nbWeeksDiff = then.diff(now, 'week')
    const nbDaysDiff = then.diff(now, 'day')
    const nbHoursDiff = then.diff(now, 'hour')

    const nbWeeks = Math.max(0, nbWeeksDiff)
    const nbDays = Math.max(0, nbDaysDiff - nbWeeksDiff * 7)
    const nbHours = Math.max(0, nbHoursDiff - nbDaysDiff * 24)

    return (
      <div className={classes.date}>
        {nbWeeks >= 0 && (
          <div className={classes.dateBlock}>
            <div className={classes.dateBig}>{nbWeeks}</div>
            <div className={classes.dateSmall}>{t('countdown_date_week', { count: nbWeeks })}</div>
            <div className={classes.dateSeparator} />
          </div>
        )}
        {nbDays >= 0 && (
          <div className={classes.dateBlock}>
            <div className={classes.dateBig}>{nbDays}</div>
            <div className={classes.dateSmall}>{t('countdown_date_day', { count: nbDays })}</div>
            <div className={classes.dateSeparator} />
          </div>
        )}
        {nbHours >= 0 && (
          <div className={classes.dateBlock}>
            <div className={classes.dateBig}>{nbHours}</div>
            <div className={classes.dateSmall}>{t('countdown_date_hour', { count: nbHours })}</div>
          </div>
        )}
      </div>
    )
  }, [
    classes.date,
    classes.dateBig,
    classes.dateBlock,
    classes.dateSeparator,
    classes.dateSmall,
    currentDate,
    date,
    t,
  ])

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        {image && (
          <CloudinaryImage
            covered
            id={image}
            options={{
              width: '1440',
              height: '860',
              crop: 'lpad',
            }}
            className={classes.image}
          >
            <div className={classes.blackFade} />
          </CloudinaryImage>
        )}
        <Icon
          icon={iconsKeys.LogoWhite}
          classname={classes.logo}
          iconProps={{ className: classes.logoAsset }}
        />
        {text && <div className={classes.text}>{text}</div>}
        {date && renderCountdown()}
        <div className={classes.footer}>
          {copyright && <div className={classes.copyright}>{copyright}</div>}
          {legalsLabel && (
            <A
              href={legalsLink}
              className={classes.link}
            >
              {legalsLabel}
            </A>
          )}
        </div>
      </div>
    </div>
  )
}

Countdown.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  image: PropTypes.string,
  copyright: PropTypes.string,
  legalsLabel: PropTypes.string,
  legalsLink: PropTypes.string,
}

Countdown.defaultProps = {
  className: '',
  text: null,
  date: null,
  image: null,
  copyright: null,
  legalsLabel: null,
  legalsLink: null,
}

export default withMemo()(Countdown)
