import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import _values from 'lodash/values'

import withMemo from '../../decorators/WithMemo'

import offerTypes from './types'
import styles from './styles'


const useStyles = createUseStyles(styles)

const OfferType = (props) => {
  const classes = useStyles(props)
  const { className, label, type, duration } = props

  const renderDuration = () => (
    <>
      <div className={classes.separator} />
      <div className={classes.duration}>{duration}</div>
    </>
  )

  return !!label
    && (
      <div className={cx(classes.container, className, type && OfferType.colors[type])}>
        <div className={classes.label}>{label}</div>
        {duration && renderDuration()}
      </div>
    )
}

OfferType.propTypes = {
  className: PropTypes.string,
  /* eslint-disable-next-line */
  type: PropTypes.oneOf(_values(offerTypes)),
  label: PropTypes.string,
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

OfferType.defaultProps = {
  className: '',
  duration: null,
  type: 'default',
  label: null,
}

OfferType.colors = {}
OfferType.colors[offerTypes.CDI] = 'egyptianBlue'
OfferType.colors[offerTypes.CDD] = 'darkBlue'
OfferType.colors[offerTypes.STAGE] = 'shamrock'
OfferType.colors[offerTypes.APPRENTISSAGE] = 'downy'
OfferType.colors[offerTypes.DEFAULT] = 'darkBlue'

export default withMemo()(OfferType)
