import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import CloudinaryImage from '../CloudinaryImage'
import { imageTypes } from '../CloudinaryImage/types'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

function SectorCard(props) {
  const classes = useStyles(props)
  const { backgroundImage, title, fields } = props

  const renderFields = () => {
    const totalFields = fields.length

    let arr1

    let
      arr2

    if (totalFields === 1) {
      arr1 = fields
      arr2 = []
    } else if (totalFields % 2 === 1 && totalFields > 1) {
      arr1 = fields.slice(0, ((totalFields - 1) / 2 + 1))
      arr2 = fields.slice(((totalFields - 1) / 2 + 1), totalFields)
    } else {
      arr1 = fields.slice(0, totalFields / 2)
      arr2 = fields.slice(totalFields / 2, totalFields)
    }

    return (
      <div className={classes.fields}>
        {arr1.length >= 1 && (
          <div className={cx(totalFields === 1 && 'no-border')}>
            {arr1.map((field, i) => (
              <div
                className={classes.fieldEntry}
                key={i}
              >
                {field.title !== null && <span dangerouslySetInnerHTML={{ __html: field.title }} />}
                {field.subTitle !== null && <p dangerouslySetInnerHTML={{ __html: field.subTitle }} />}
              </div>
            ))}
          </div>
        )}
        {arr2.length >= 1 && (
          <div>
            {arr2.map((field, i) => (
              <div
                className={classes.fieldEntry}
                key={i}
              >
                {field.title !== null && <span dangerouslySetInnerHTML={{ __html: field.title }} />}
                {field.subTitle !== null && <p dangerouslySetInnerHTML={{ __html: field.subTitle }} />}
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={classes.container}>
      {backgroundImage && (
        <CloudinaryImage
          id={backgroundImage}
          className={classes.overlay}
          type={imageTypes.Background}
          lazyLoad
        />
      )}
      <div className={classes.content}>
        {title && (
          <div
            className={classes.title}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        {fields && fields.length >= 1 && renderFields()}
      </div>
    </div>
  )
}

SectorCard.styles = styles

SectorCard.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    subTitle: PropTypes.string,
  })),
  backgroundImage: PropTypes.string,
}

SectorCard.defaultProps = {
  title: null,
  fields: [],
  backgroundImage: null,
}

export default withMemo()(SectorCard)
