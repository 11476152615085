import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'

import Domain from '../../data/models/Domain'
import withMemo from '../../decorators/WithMemo'
import { colors } from '../../theme'
import Tooltip from '../../utils/ReactTooltipUtils'
import A from '../A'
import CloudinaryImage from '../CloudinaryImage'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'

import styles from './styles'


const defaultLogo = 'defaults/logo'
const useStyles = createUseStyles(styles)

const CompanyCard = (props) => {
  const classes = useStyles(props)
  const {
    className,
    name,
    city,
    logo,
    domain,
    link,
    route,
    routeParams,
    hasCertifications,
    isAmbassador,
    is_ogcn,
    isCurrentOGCN,
  } = props
  const { t } = useTranslation()

  return (
    <>
      <Tooltip />
      <A
        href={link}
        route={route}
        routeParams={routeParams}
        className={cx(classes.container, className)}
        title={name}
      >
        <div className={classes.wrapper}>
          <CloudinaryImage
            id={logo || defaultLogo}
            options={{
              width: '80',
              height: '70',
              crop: 'lpad',
            }}
            lazyLoad
            className={classes.logo}
          />
          <div className={classes.content}>
            {name && (
              <h2 className={classes.name}>{name}</h2>
            )}
            {domain?.name && (
              <div className={classes.sector}>{domain?.name}</div>
            )}
            {city && city !== '' && (
              <div className={classes.city}>
                <Icon
                  icon={iconsKeys.Pinpoint}
                  className={classes.cityIcon}
                />
                <span className={classes.cityLabel}>{city}</span>
              </div>
            )}
          </div>
          {isAmbassador && (
            <Icon
              icon={iconsKeys.Ambassador}
              className={classes.smallIcon}
              data-tip={t('companyCardAmbassador')}
              data-multiline="false"
              data-background-color={colors.nightRider}
              data-text-color={colors.pureWhite}
              data-class={classes.labelTooltip}
            />
          )}
          {hasCertifications && (
            <Icon
              icon={iconsKeys.LabelCircle}
              className={classes.smallIcon}
              data-tip={t('companyCardCertification')}
              data-multiline="false"
              data-background-color={colors.nightRider}
              data-text-color={colors.pureWhite}
              data-class={classes.labelTooltip}
            />
          )}
          {isCurrentOGCN && is_ogcn && (
            <div className={classes.boxOgcn}>
              <Icon
                icon={iconsKeys.OgcnLight}
                className={classes.iconOgcn}
              />
            </div>
          )}
        </div>
      </A>
    </>
  )
}

CompanyCard.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  domain: Domain.propTypes,
  city: PropTypes.string,
  logo: PropTypes.string,
  link: PropTypes.string,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
  hasCertifications: PropTypes.bool,
  isAmbassador: PropTypes.bool,
  is_ogcn: PropTypes.bool,
  isCurrentOGCN: PropTypes.bool,
}

CompanyCard.defaultProps = {
  className: '',
  name: null,
  domain: null,
  city: null,
  logo: null,
  link: null,
  route: null,
  routeParams: null,
  hasCertifications: false,
  isAmbassador: false,
  is_ogcn: false,
  isCurrentOGCN: false,
}

export default withMemo()(CompanyCard)
