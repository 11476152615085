/* eslint-disable max-lines */
import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { Form, Formik } from 'formik'

import { validateAll } from '../../helpers/ValidationHelpers'
import { compileInitialValues, compileRules, defineField, formFields } from '../../helpers/FormHelpers'
import { iconsKeys } from '../Icon/Icon.assets'
import { colors } from '../../theme'
import { Field } from '../form/Generator/Field'
import withMemo from '../../decorators/WithMemo'
import Submit from '../form/Submit'

import styles from './styles'


export const fields = [
  defineField({ name: 'message', validation: ['required'], component: formFields.Textarea }),
  defineField({ name: 'files', component: formFields.UploadFiles, media: 1 }),
]

const rules = compileRules(fields)

const useStyles = createUseStyles(styles)

const OfferApplyForm = (props) => {
  const classes = useStyles(props)
  const {
    className,
    initialValues,
    submitLabel,
    onSubmit,
    isPending,
    isSuccess,
    messages,
    fieldsProps,
    errors: backendErrors,
  } = props

  //
  // Handlers
  //
  const handleValidate = (values) => validateAll(values, rules, messages)
  const handleSubmit = (values) => onSubmit(values)

  //
  // Renderers
  //
  const renderForm = (formikProps) => (
    <Form
      noValidate
      autoComplete="off"
      onSubmit={formikProps.handleSubmit}
    >
      <div className={classes.fields}>
        {
          fields
            .map((field, i) => (
              <div
                className={cx(classes.field, classes[`field_${field.name}`])}
                key={i}
              >
                <Field
                  fieldConfig={field}
                  fieldsProps={fieldsProps}
                  formikProps={formikProps}
                />
              </div>
            ))
        }
      </div>

      {backendErrors && (
        <div className={classes.errors}>
          {backendErrors.map((error, i) => (
            <div
              className={classes.error}
              key={i}
            >
              {error.label}
            </div>
          ))}
        </div>
      )}

      <div className={classes.submitContainer}>
        <Submit
          isPending={isPending}
          isSuccess={isSuccess}
          color="primary"
          isOutlined
          isBig
          label={submitLabel}
          className={classes.button}
          icon={iconsKeys.ArrowRight}
          iconRight
          colorIcon={colors.tangerine}
          colorIconHover={colors.pureWhite}
        />
      </div>
    </Form>
  )

  return (
    <div className={cx(classes.container, className)}>
      <Formik
        validate={handleValidate}
        onSubmit={handleSubmit}
        initialValues={compileInitialValues(fields, initialValues)}
        enableReinitialize
      >
        {renderForm}
      </Formik>
    </div>
  )
}

OfferApplyForm.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line
  initialValues: PropTypes.object,
  submitLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  fieldsProps: PropTypes.objectOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    help: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, value: PropTypes.string })),
  })),
  messages: PropTypes.objectOf(PropTypes.string),
  errors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    field: PropTypes.string,
  })),
  isPending: PropTypes.bool,
  isSuccess: PropTypes.bool,
}

OfferApplyForm.defaultProps = {
  className: '',
  isPending: false,
  isSuccess: false,
  initialValues: {},
  submitLabel: '',
  fieldsProps: {},
  messages: {},
  onSubmit: () => null,
  errors: null,
}

export default withMemo()(OfferApplyForm)
