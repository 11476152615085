import React, { useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import CloudinaryImage from '../CloudinaryImage'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import ActionButton from '../ActionButton'
import withMemo from '../../decorators/WithMemo'
import { colors } from '../../theme'

import styles from './styles'


const useStyles = createUseStyles(styles)

const RelationCard = (props) => {
  const classes = useStyles(props)
  const {
    className,
    id,
    avatar,
    name,
    companyFunction,
    company,
    matchingPercentage,
    matchingDomains,
    companyDomain,
    contactClick,
    contactButtonLabel,
  } = props

  const [isMatchingHovered, setIsMatchingHovered] = useState(false)
  const [isDomainHovered, setIsDomainHovered] = useState(false)

  const handleContactClick = () => {
    if (handleContactClick) {
      contactClick(id, name, avatar, company)
    }
  }

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <div className={classes.head}>
          <div className={classes.left}>
            <CloudinaryImage
              id={avatar}
              className={classes.avatar}
            />
            <div className={classes.user}>
              <div className={classes.name}>
                {name}
              </div>
              <div className={classes.companyFunction}>
                {companyFunction?.name}
              </div>
              <div className={classes.companyName}>
                {company?.name}
              </div>
            </div>
          </div>
          {companyDomain && companyDomain?.icon && (
            <div className={classes.right}>
              <Icon
                className={classes.icon}
                icon={companyDomain.icon}
                color={companyDomain.color}
                onMouseEnter={() => setIsDomainHovered(true)}
                onMouseLeave={() => setIsDomainHovered(false)}
              />
              <div className={isDomainHovered ? cx(classes.hoveredIcon, classes.show) : classes.hoveredIcon}>
                <div className={classes.hoveredBox}>
                  <div className={classes.item}>
                    {companyDomain.text}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={classes.foot}>
          <div
            className={classes.matching}
            onMouseEnter={() => setIsMatchingHovered(true)}
            onMouseLeave={() => setIsMatchingHovered(false)}
          >
            {matchingDomains && matchingDomains.length > 0 && (
              <div className={isMatchingHovered ? cx(classes.hoveredDomain, classes.show) : classes.hoveredDomain}>
                <div className={classes.hoveredBox}>
                  {matchingDomains?.map((domain, i) => (
                    <div
                      key={i}
                      className={classes.item}
                    >
                      <div>{domain.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <Icon
              className={classes.matchingIcon}
              icon={iconsKeys.Relation}
            />
            <div className={classes.matchingPercentage}>
              {matchingPercentage}
            </div>
          </div>
          <ActionButton
            className={classes.contactButton}
            icon={iconsKeys.Chat}
            iconRight
            label={contactButtonLabel}
            onClick={handleContactClick}
            color="secondary"
            isOutlined
            colorIcon={colors.secondary}
            colorIconHover={colors.pureWhite}
          />
        </div>
      </div>
    </div>
  )
}

RelationCard.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  avatar: PropTypes.string,
  name: PropTypes.string,
  companyFunction: PropTypes.shape({
    name: PropTypes.string,
  }),
  company: PropTypes.shape({
    name: PropTypes.string,
    icon: PropTypes.string,
  }),
  matchingPercentage: PropTypes.string,
  matchingDomains: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      sector: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    })
  ),
  companyDomain: PropTypes.shape({
    text: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
  }),
  contactClick: PropTypes.func,
  contactButtonLabel: PropTypes.string,
}

RelationCard.defaultProps = {
  className: '',
  id: '',
  avatar: '',
  name: '',
  companyFunction: {},
  company: {},
  matchingPercentage: '',
  matchingDomains: [],
  companyDomain: '',
  contactClick: null,
  contactButtonLabel: '',
}

export default withMemo()(RelationCard)
