/* eslint-disable max-lines */

import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'react-i18next'

import withMemo from '../../decorators/WithMemo'
import { colors } from '../../theme'
import { cutString } from '../../utils/StringUtils'
import A from '../A'
import ActionButton from '../ActionButton'
import CloudinaryImage from '../CloudinaryImage'
import { imageTypes } from '../CloudinaryImage/types'
import FileLibrary from '../FileLibrary'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import RegisterModal from '../RegisterModal'

import styles from './styles'


const defaultLogo = 'defaults/logo'
const useStyles = createUseStyles(styles)

const PostCard = (props) => {
  const classes = useStyles(props)
  const {
    className,
    isConnected,
    isPremium,
    title,
    description,
    image,
    user,
    files,
    link,
    linkLabel,
    isPublished,
    isOGCN,
    typeLabel,
    dateLabel,
    shortDateLabel,
    seeProfileLabel,
    seeProfileLink,
    seeProfileRoute,
    seeProfileRouteParams,
    small,
    actions,
    isCurrentOGCN, // Is the current user an OGCN member?
    type,
  } = props

  const { t } = useTranslation()

  const [modalOpen, setModalOpen] = useState(false)
  const handleOpenModal = useCallback(() => setModalOpen(true), [])
  const handleCloseModal = useCallback(() => setModalOpen(false), [])

  const renderAction = () => {
    if (!isConnected) {
      return (
        <div className={classes.footer}>
          <ActionButton
            noBg
            iconRight
            label={seeProfileLabel}
            color="white"
            icon={iconsKeys.ArrowRight}
            colorIcon={colors.darkBlue}
            className={classes.contact}
            onClick={handleOpenModal}
          />
        </div>
      )
    }

    if (!isPremium) {
      return (
        <div className={classes.footer}>
          <ActionButton
            noBg
            iconRight
            label={seeProfileLabel}
            color="white"
            icon={iconsKeys.ArrowRight}
            colorIcon={colors.darkBlue}
            className={classes.contact}
            onClick={handleOpenModal}
          />
        </div>
      )
    }

    if (actions) {
      return (
        <div className={classes.bottom}>
          <div className={classes.buttons}>
            {actions.map((action, key) => (
              <ActionButton
                isOutlined
                key={key}
                color="secondary"
                className={classes.button}
                {...action}
              />
            ))}
          </div>
        </div>
      )
    }

    return (
      <div className={classes.footer}>
        <ActionButton
          noBg
          iconRight
          label={seeProfileLabel}
          link={seeProfileLink}
          route={seeProfileRoute}
          routeParams={seeProfileRouteParams}
          color="white"
          icon={iconsKeys.ArrowRight}
          colorIcon={colors.darkBlue}
          className={classes.contact}
        />
      </div>
    )
  }

  return (
    <div className={cx(classes.container, !isPublished && classes.containerBlue, className)}>
      <div className={classes.header}>
        <div className={classes.author}>
          <CloudinaryImage
            id={user?.company?.logo || defaultLogo}
            className={classes.logo}
            type={imageTypes.Image}
            options={{
              width: '50',
              height: '50',
              crop: 'fit',
            }}
            lazyLoad
          />
          <div className={classes.user}>
            {isConnected && (
              <div className={classes.name}>
                {`${user?.first_name} ${user?.last_name}`}
              </div>
            )}
            <div className={isConnected ? classes.company : classes.companySingle}>
              {user?.company?.name}
            </div>
          </div>
        </div>
        <div className={classes.infos}>
          {isCurrentOGCN && isOGCN && (
            <div className={classes.boxOgcn}>
              <Icon
                icon={iconsKeys.OgcnLight}
                className={classes.iconOgcn}
              />
            </div>
          )}
          <div className={classes.infosDetails}>
            <div className={classes.type}>
              {typeLabel}
            </div>
            <div className={classes.publishDate}>
              <span className={classes.dateLabel}>{dateLabel}</span>
              <span className={classes.shortDateLabel}>{shortDateLabel}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.content}>
        {!!image && !small && (
          <CloudinaryImage
            id={image}
            className={classes.image}
            type={imageTypes.Image}
            covered
            options={{
              width: '360',
              height: '200',
              crop: 'fill',
            }}
            lazyLoad
          />
        )}
        <div className={classes.body}>
          <h2 className={cx(classes.title, !description && classes.titleBig)}>
            {title}
          </h2>
          {!!description && (
            <div className={classes.description}>
              {small ? cutString(description, 30, '[...]') : description}
            </div>
          )}
          {!!link && (
            <A
              href={link}
              target="_blank"
              className={cx(classes.link, !isPublished && classes.linkWhite)}
            >
              <div className={classes.linkText}>
                {linkLabel}
              </div>
              <Icon
                color={colors.darkBlue}
                icon={iconsKeys.ArrowRight}
                className={classes.linkIcon}
              />
            </A>
          )}
          {isConnected && Boolean(files?.files?.length) && (
            <FileLibrary
              {...files}
              className={classes.files}
            />
          )}
        </div>
      </div>
      <RegisterModal
        modalTitle={t('postCard_contact_title_unauthenticated')}
        modalText={t('postCard_contact_text_unauthenticated')}
        modalCta={t('postCard_contact_cta_unauthenticated')}
        isOpen={modalOpen}
        onClose={handleCloseModal}
        premiumStep
      />
      {renderAction()}
    </div>
  )
}

PostCard.propTypes = {
  className: PropTypes.string,
  isConnected: PropTypes.bool,
  isPremium: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
    slug: PropTypes.string,
    avatar: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    company: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
  }),
  files: PropTypes.shape(FileLibrary.propTypes),
  link: PropTypes.string,
  linkLabel: PropTypes.string,
  isPublished: PropTypes.bool,
  isOGCN: PropTypes.bool,
  typeLabel: PropTypes.string,
  dateLabel: PropTypes.string,
  shortDateLabel: PropTypes.string,
  seeProfileLabel: PropTypes.string,
  seeProfileLink: PropTypes.string,
  seeProfileRoute: PropTypes.string,
  seeProfileRouteParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
  small: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    icon: PropTypes.string,
    route: PropTypes.string,
    routeParams: PropTypes.object,
  })),
  isCurrentOGCN: PropTypes.bool,
  type: PropTypes.string,
}

PostCard.defaultProps = {
  className: '',
  isConnected: true,
  isPremium: true,
  title: null,
  description: null,
  image: null,
  user: null,
  files: null,
  link: null,
  linkLabel: null,
  isPublished: false,
  isOGCN: false,
  typeLabel: null,
  dateLabel: null,
  shortDateLabel: null,
  seeProfileLabel: null,
  seeProfileLink: null,
  seeProfileRoute: null,
  seeProfileRouteParams: null,
  small: false,
  actions: null,
  isCurrentOGCN: false,
  type: null,
}

export default withMemo()(PostCard)
